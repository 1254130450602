import React, { useEffect, useState, useRef } from "react"

import "url-search-params-polyfill"
import { useStaticQuery, graphql, Link } from "gatsby"
import { inject, observer } from "mobx-react"
import * as JsSearch from "js-search"

import { convertStringFromCamelcase } from "../helpers/helpers"
import { SEO } from "../components/base/seo/seo"
import { Wrapper } from "../components/base/wrapper/wrapper"
import { NoResults } from "../components/modules/no-results/no-results"

const Search = inject("regionStore")(
  observer(({ regionStore }) => {
    const { letters, sheets, pages } = useStaticQuery(
      graphql`
        query {
          letters: wagtail {
            pages {
              ... on SampleLetter {
                id
                title
                description
                url
                validInEngland
                validInScotland
                instructions
                sampleLetterContent
                categories {
                  name
                }
              }
            }
          }
          sheets: wagtail {
            pages {
              ... on FactSheet {
                id
                title
                description
                url
                factSheetContent
                factSheetCategories {
                  category {
                    id
                    name
                  }
                }
                validInEngland
                validInScotland
              }
            }
          }
          pages: wagtail {
            pages {
              ... on GridPage {
                id
                title
                description
                url
                body {
                  ... on StructBlock {
                    blocks {
                      ... on StreamBlock {
                        blocks {
                          ... on StructBlock {
                            blocks {
                              ... on CharBlock {
                                field
                                value
                              }
                              ... on RichTextBlock {
                                rawValue
                                field
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on BasicPage {
                id
                title
                description
                url
                body {
                  ... on RichTextBlock {
                    value
                  }
                  ... on StreamFieldBlock {
                    value
                  }
                }
              }
            }
          }
        }
      `
    )

    const searchInput = useRef(null)

    const [search, setSearch] = useState(undefined)
    const [searchQuery, setSearchQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])

    useEffect(() => {
      rebuildIndex()
    }, [])

    useEffect(() => {
      const isSearchInitiated = typeof search === "object"

      if (isSearchInitiated) {
        const urlParams = new URLSearchParams(window.location.search)
        const initialQuery = urlParams.get("query")

        // Emulate an event.
        if (initialQuery) {
          searchData({ target: { value: initialQuery } })
        }
      }
    }, [search])

    const addType = (items, type) =>
      items.map(item => {
        item.type = type
        return item
      })

    const data = [
      ...addType(letters.pages, "sampleLetter"),
      ...addType(sheets.pages, "factSheet"),
      ...addType(pages.pages, "page").map(page => {
        // Map Streamfields into a single string to make search possible.
        // (js-search does not offer the ability to search arrays).
        if (page["__typename"] === "BasicPage") {
          if (Array.isArray(page.body)) {
            page.body = page.body.map(({ value }) => value).join(" ")
          }
        } else {
          if (page.body && Array.isArray(page.body)) {
            page.body = page.body
              .map(({ blocks }) =>
                blocks
                  .filter(block => block.blocks)
                  .map(block =>
                    block.blocks
                      .map(secondBlock =>
                        secondBlock.blocks
                          .map(thirdBlock =>
                            thirdBlock.value
                              ? thirdBlock.value
                              : thirdBlock.rawValue
                          )
                          .join(" ")
                      )
                      .join(" ")
                  )
                  .join(" ")
              )
              .join(" ")
          }
        }
        return page
      }),
    ]

    const queryResults =
      searchQuery === ""
        ? data
        : searchResults.filter(result => {
            if (result.factSheetCategories || result.categories) {
              const category = result.factSheetCategories
                ? result.factSheetCategories.map(
                    element => element["category"]["name"]
                  )
                : result.categories["name"]
              return !category.includes("Hidden")
            }
            return result
          })

    const rebuildIndex = () => {
      const dataToSearch = new JsSearch.Search("id")

      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
      dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
      dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id")

      // Generic fields to query
      dataToSearch.addIndex("title")
      dataToSearch.addIndex("description")

      // Sample letter fields
      dataToSearch.addIndex("instructions")
      dataToSearch.addIndex("sampleLetterContent")

      // Fact sheet fields
      dataToSearch.addIndex("factSheetContent")

      // Grid and Basic page fields
      dataToSearch.addIndex("body")

      // Pages to search through
      dataToSearch.addDocuments(data)

      setSearch(dataToSearch)
    }

    const searchData = ({ target }) => {
      const queryResult = search.search(target.value)

      updateUrlQueryParams(target.value)

      setSearchQuery(target.value)
      setSearchResults(queryResult)
    }

    const updateUrlQueryParams = value => {
      const { origin, pathname } = window.location
      window.history.replaceState(
        null,
        "query",
        `${origin}${pathname}?query=${value}`
      )
    }

    const handleSubmit = e => {
      e.preventDefault()
    }

    const changeFocusToSearch = e => {
      e.preventDefault()
      searchInput.current.focus()
    }

    const filterByRegion = ({ validInEngland, validInScotland }) =>
      validInEngland !== undefined && validInScotland !== undefined
        ? regionStore.currentRegion === "EW"
          ? validInEngland
          : validInScotland
        : true

    const getResults = type => {
      const results = queryResults
        .filter(result => result.type === type)
        .filter(filterByRegion)

      return results.length ? (
        results.map(({ title, description, url }) => (
          <div key={url} className="mb-4">
            <Link to={url}>
              <h3 className="h5">{title}</h3>
            </Link>
            {description}
          </div>
        ))
      ) : (
        <p>No {convertStringFromCamelcase(type)}s found.</p>
      )
    }

    return (
      <Wrapper>
        <SEO
          title={`Search ${
            searchQuery.length ? `results for '${searchQuery}'` : ""
          }`}
        />

        <div className="row">
          <div className="offset-md-2 col-12 col-md-8">
            <h1 className="mb-3">Search results</h1>

            <form onSubmit={handleSubmit} className="form-group row mb-3">
              <label htmlFor="search" className="col-12 col-form-label">
                Search our debt resources:
              </label>

              <div className="col-12">
                <input
                  id="search"
                  name="search"
                  ref={searchInput}
                  value={searchQuery}
                  className="form-control"
                  onChange={searchData}
                  placeholder="Search..."
                />

                <small className="form-text text-muted">
                  Use this box to search for fact sheets, sample letters, and
                  pages.
                </small>
              </div>
            </form>

            {!searchQuery ? (
              <p className="mt-6 pt-2 text-center font-weight-bold">
                Once you've&nbsp;
                <a
                  href="#"
                  aria-label="go to search field"
                  className="text-primary link"
                  onClick={changeFocusToSearch}
                >
                  performed a search
                </a>
                , you'll see the results here.
              </p>
            ) : searchResults.length ? (
              <>
                <p className="mt-4">
                  Total results found:&nbsp;
                  <strong>{queryResults.filter(filterByRegion).length}</strong>
                </p>

                <div className="border-top pb-3 pt-4">
                  <h2 className="h3">Fact Sheets</h2>
                  {getResults("factSheet")}
                </div>

                <div className="border-top pb-3 pt-4">
                  <h2 className="h3">Sample Letters</h2>
                  {getResults("sampleLetter")}
                </div>

                <div className="border-top pb-3 pt-4">
                  <h2 className="h3">Pages</h2>
                  {getResults("page")}
                </div>
              </>
            ) : (
              <NoResults query={searchQuery} />
            )}
          </div>
        </div>
      </Wrapper>
    )
  })
)

export default Search
